import React from "react";
import { Link } from "react-router-dom";
import { moodLogos } from "./MoodLogos";
import Rating from "./Rating";

export default function Product(props) {
  const { product } = props;

  return (
    <div>
      <Link
        to={`/channel/${product.name.replaceAll(" ", "_").toLowerCase()}-${
          product._id
        }`}
      >
        <div key={product._id} className="card">
          <div className="crop">
            <Link
              to={`/channel/${product.name
                .replaceAll(" ", "_")
                .replaceAll("-", "_")
                .toLowerCase()}-${product._id}`}
            >
              <img
                className="profileImage"
                src={product.profileImage}
                alt={"YouTube channel " + product.name}
              />
              <img
                className="medium"
                src={product.image}
                alt={product.name}
                loading="lazy"
              />
            </Link>
          </div>
          <div className="card-body">
            <Link
              to={`/channel/${product.name
                .replaceAll(" ", "_")
                .replaceAll("-", "_")
                .toLowerCase()}-${product._id}`}
            >
              <h3> {product.name} </h3>
            </Link>
            <Rating
              rating={product.rating}
              numReviews={product.numReviews}
              mean={true}
            ></Rating>
            <div>
              <strong>Users found it:</strong>
            </div>
            <div>
              <text>
                {product.rankingRatingMoodDef ? (
                  product.rankingRatingMoodDef.slice(0, 2).map((x) => (
                    <text id="coma">
                      <i
                        className={
                          moodLogos.find((y) => y.name === x)
                            ? moodLogos.find((y) => y.name === x).emoticonClass
                            : ""
                        }
                      ></i>
                      {"  "}
                      {x?.replace("Fun", "Funny").replace("Funnyny", "Funny")}
                      {"  "}
                    </text>
                  ))
                ) : (
                  <div></div>
                )}
              </text>
            </div>
            <div className="card card-body3-product">
              {product?.allTopics?.[0]?.mainTopic && (
                <div className="topics">
                  <ul>
                    {[
                      ...new Set(
                        product.allTopics.map((item) => item.mainTopic)
                      ),
                    ].map((x) => (
                      <div className="topic">{x?.replace(" and", " &")} </div>
                    ))}
                    {product.allTopics
                      .filter((topic) => topic.subTopic !== "Multi-topic")
                      .filter((topic) => topic.subTopic !== "General")

                      .map((topic) => (
                        <div className="topic">
                          {topic.subTopic?.replace(" and", " &")}{" "}
                        </div>
                      ))}
                    <div className="topic">
                      {product.category?.split(" ")[0]}{" "}
                    </div>

                    {product.tag?.map((x) => (
                      <li key={x}>
                        <div className="topic">{x} </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="card card-body3-product">
              <div className="half">
                <div>
                  <i class="fa-solid fa-users"></i>
                </div>
                <div>
                  <strong>Subscribers</strong>{" "}
                </div>
                <div>
                  <text>
                    {product.subscriberCount >= 1000000
                      ? Math.round(product.subscriberCount / 100000) / 10 + "M"
                      : product.subscriberCount >= 10000
                      ? Math.round(product.subscriberCount / 1000) + "K"
                      : product.subscriberCount >= 1000
                      ? Math.round(product.subscriberCount / 100) / 10 + "K"
                      : product.subscriberCount}
                  </text>
                </div>
              </div>

              <div className="half">
                <div>
                  <i class="fa-solid fa-clock"></i>{" "}
                </div>
                <div>
                  <strong>Average duration</strong>
                </div>
                <div>
                  <text>{product.duration + " min"}</text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
